
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlideOne from "./slides/SlideOne.client";
import SlideTwo from "./slides/SlideTwo.client";
import SlideThree from "./slides/SlideThree.client";

export default function MainHero() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows:false,
    };

    return (
        <div className="w-[96%] h-[70vh] bg-none cursor-grab">
            <Slider {...settings}>
                <div className="w-full">
                    <SlideOne/>
                </div>
                <div className="w-full">
                    <SlideTwo/>
                </div>
                <div className="w-full">
                    <SlideThree />
                </div>
            </Slider>
        </div>

    )
}

