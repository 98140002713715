import React from 'react'

export default function SlideTwo() {
  return (
    <div className="w-full h-[70vh] bg-gradient-to-r from-[#DFE0E3] via-[#F0EFF3] to-[#F3F2F7]">
    <div className="flex justify-center items-center h-full">
        <div className="  w-full h-full bg-[url('./assets/banner.jpg')] bg-cover bg-center ">
        </div>
    </div>
</div>
  )
}
